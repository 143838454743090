import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";

import ForgotPasswordForm from "./fogotPasswordForm";
import ResetPasswordForm from "./resetPasswordForm";
import * as LoginActions from "../store/auth.action";

import Logo from "../../../assets/images/logo.png";
import LogoPro from "../../../assets/images/logoPro.png";
import Footer from "../../../common/component/footer";

const ForgotPassword = (props) => {
  const [state, setState] = useState({
    isDropdownActive: false,
  });

  const appMode = localStorage.getItem("app_mode");

  if (props.isResetPassword) {
    return <Navigate to="/login" />;
  }
  return (
    <section
      className="home-section forgot-password"
      onClick={() => state.isDropdownActive}
    >
      <div className="container">
        <div className="gray-wrapper">
          <header className="header d-flex align-items-center justify-content-between">
            <div className="logo">
              <Link to="/login">
                {appMode === "standard" ? (
                  <img src={Logo} alt="logo" />
                ) : (
                  <img src={LogoPro} alt="LogoPro" />
                )}
              </Link>
            </div>
          </header>
          <div className="section-heading">
            <Link
              className="mb-2 back-btn"
              to="/login"
              onClick={() => {
                localStorage.removeItem("email");
              }}
            >
              <svg
                width="40px"
                height="40px"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
              Back
            </Link>
            <h3 className="">Forgot Password</h3>
          </div>
          <div className="login-box">
            {!props.forgotPassEmail && (
              <ForgotPasswordForm
                loading={props.authLoading}
                onSubmit={props.forgotPassword}
              />
            )}
            {props.forgotPassEmail && (
              <ResetPasswordForm
                initialValues={{
                  email: props.forgotPassEmail,
                  code: "",
                  password: "",
                }}
                loading={props.authLoading}
                onSubmit={props.resetPassword}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  ...state.common,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data) => LoginActions.forgotPassword(data, dispatch),
  resetPassword: (data) => LoginActions.resetPassword(data, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
