import React from "react";
import HistoryCard from "./historyCard";
import { Link } from "react-router-dom";

const Home = (props) => {
  return (
    <>
      <h3 className="">History</h3>
      <div className="tab-wrapper">
        <ul className="">
          <li
            onClick={() => props.onTabChange("")}
            className={props.tab === "" ? "active" : ""}
          >
            Learning
          </li>
          <li
            onClick={() => props.onTabChange("actual")}
            className={props.tab === "actual" ? "active" : ""}
          >
            Actual Test
          </li>
        </ul>
        <div className="mr-2">
          <div className="profile-dropdown">
            <Link to="subscription/plan" className="payment-btn">
              <span>Subscribe</span>
            </Link>
          </div>
        </div>
        {/* <a href='#'>
                    Subscribe
                </a> */}
      </div>
      {props.loading && (
        <div className="loader text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        </div>
      )}
      {props.testData.length > 0 && (
        <div
          className={
            props.tab
              ? "history-card-wrapper actual"
              : "history-card-wrapper learning"
          }
        >
          <div className="history-header">
            <div className="item">Location</div>
            <div className="item">{props?.tab ? "Center" : "Routes"}</div>
            <div className="item">Status</div>
            <div className="item">Date and Time</div>
          </div>
          {!props.loading &&
            props.testData.length > 0 &&
            props.testData.map((test) => (
              <HistoryCard
                key={test.id}
                id={test.id}
                routeName={
                  props.tab ? test.centerRouteName : test.centerRouteName
                }
                centerName={props.tab ? test.centerName : test.centerName}
                startedTime={props.tab ? test.completedAt : test.startedAt}
                status={props.tab ? test.status : test.status}
              />
            ))}
        </div>
      )}
      {props.isScrolling && (
        <div className="loader text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        </div>
      )}

      {!props.loading && props.testData.length === 0 && (
        <div className="empty-data-text d-flex align-items-center justify-content-center">
          <h3 className="mt-0 mb-0 font-bold">No Data Found</h3>
        </div>
      )}
    </>
  );
};

export default Home;
