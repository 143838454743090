import React from 'react';
import { getFullDate, getTime } from '../../../common/helper/helper';

const HistoryCard = props => {
    const date = getFullDate(props.startedTime);
    const time = getTime(props.startedTime);

    // const onClickHistoryCard = () => {
    //     changeIsOpen(true);
    //     axios.get(`${API_CONFIG.traversal}/${props.id}`, { headers: { accesstoken: getToken() } }).then(response => {
    //         console.log(response.data)
    //         changeTraversalDetail(response.data);
    //     }).catch(error => console.log(error));
    // };

    return (
        <>
            <div className='history-card'>
                <div className='info-item'>
                    <p className='mb-0'>{props.centerName}</p>
                </div>
                {props.routeName && 
                    <div className='info-item'>
                        <p className='mb-0'>{props.routeName}</p>
                    </div>
                }
                <div className='info-item'>
                <p  className='mb-0'>{props.status}</p>
                </div>
                <div className='info-item'>
                    <p className='mb-0'>{date} {time}</p>
                </div>
            </div>
        </>
    )
}

export default HistoryCard;