import React from "react";
import StripeCheckout from "react-stripe-checkout";
import { fromDollarToCent } from "../../../common/helper/helper";

const Checkout = (props) => {
  const {
    name,
    email,
    description,
    amount,
    currency,
    stripePublishableKey,
    onSubmitPayment,
    paymentDoneStatus,
    label,
    productId,
    couponCode,
    centerId,
  } = props;

  return (
    <>
      <StripeCheckout
        label={label}
        name={name}
        email={email}
        description={description}
        image="https://www.testroutes.co.uk/wp-content/uploads/2020/05/TestRoutesLogo.png"
        amount={fromDollarToCent(amount)}
        token={onSubmitPayment(productId, couponCode, centerId)}
        currency={currency}
        stripeKey={stripePublishableKey}
        zipCode
        allowRememberMe
      />
    </>
  );
};

export default Checkout;
