import React, { useEffect } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/app.scss";
import Login from "./components/auth/container/login";
import Signup from "./components/auth/container/signup";
import Subscription from "./components/auth/container/subscription";
import ForgotPassword from "./components/auth/component/forgotPassword";
import Notification from "./common/component/notification";
import Home from "./components/home/container/home";
import SubscriptionPlan from "./components/home/component/subcriptionPlan";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { getAppMode } from "./common/authService";
import FavIconPro from "./assets/images/Pro-favicon.svg";
import FavIcon from "./assets/images/favicon.svg";

const App = (props) => {
  const [logo, setLogo] = useState(
    getAppMode() === "pro" ? "testRoutePro" : ""
  );
  const appMode = localStorage.getItem("app_mode");
  useEffect(() => {
    const favicon = document.querySelector("link[rel='icon']");
    document.title = appMode === "standard" ? "Test Routes" : "Test Routes Pro";
    if (appMode === "standard") {
      favicon.href = FavIcon;
    } else {
      favicon.href = FavIconPro;
    }
  }, [appMode]);

  return (
    <div className="App">
      <Notification />
      <BrowserRouter>
        {props.auth && (
          <Routes>
            <Route exact path="/" element={<Home logo={{ logo, setLogo }} />} />
            <Route
              exact
              path="/subscription/payment"
              element={<Subscription />}
            />
            <Route
              exact
              path="/subscription/plan"
              element={<SubscriptionPlan logo={{ logo, setLogo }} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
        {!props.auth && (
          <Routes>
            <Route
              exact
              path="/login"
              element={<Login logo={{ logo, setLogo }} />}
            />
            <Route
              exact
              path="/signup"
              element={<Signup logo={{ logo, setLogo }} />}
            />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/subscription" element={<Subscription />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.common.auth,
});

export default connect(mapStateToProps)(App);
