import React from "react";
import { Link } from "react-router-dom";
import SignupForm from "./signupForm";

import Logo from "../../../assets/images/logo.png";
import LogoPro from "../../../assets/images/logoPro.png";
import victory from "../../../assets/images/victory.png";
import Footer from "../../../common/component/footer";

const Signup = (props) => {
  return (
    <section className="login-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-lg-6  col-md-12 login-col">
            <div className="login-wrapper">
              <header className="header">
                <div className="logo">
                  <Link to="/login">
                    {props.logo.logo === "testRoutePro" ? (
                      <img src={LogoPro} alt="logoPro" />
                    ) : (
                      <img src={Logo} alt="logo" />
                    )}
                  </Link>
                </div>
              </header>
              <div className="section-heading">
                <h3 className=" mt-0">
                  Create Account <img src={victory} alt="Victory" />
                </h3>
                <p className="mb-0 mt-0">Sign up to continue!</p>
              </div>
              <div className="login-box">
                <SignupForm {...props} />
              </div>
              <div className="no-account-text text-center">
                <span>
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="text-primary"
                    onClick={() => {
                      props.logo.setLogo("testRoutePro");
                      localStorage.removeItem("fullName");
                      localStorage.removeItem("email");
                      localStorage.removeItem("password");
                    }}
                  >
                    Sign In
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6  col-md-12 cover-col">
            <div className="we-cover">
              <div className="left">
                <h4>
                  <span>We cover </span>Postal areas driving{" "}
                  <b>
                    <span>Test</span>Routes.
                  </b>
                </h4>
              </div>
              <div className="right">
                <div className="stats">
                  <div id="number1">98</div>
                  <sup>%</sup>
                </div>
              </div>
            </div>
            <div className="dont-have-account">
              <span>
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="text-primary"
                  onClick={() => {
                    localStorage.removeItem("fullName");
                    localStorage.removeItem("email");
                    localStorage.removeItem("password");
                  }}
                >
                  Sign In
                </Link>
              </span>
            </div>
            <div className="claim-text">
              <p>
                The number <b>01</b> app for all learners and driving
                instructors
              </p>
              <h4>
                Claim your 7 Days <span>Free Trial</span> Now
              </h4>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </section>

    // <section className='auth-section login-section d-flex align-items-center'>
    //     <div className='container'>

    //     </div>
    // </section>
  );
};

export default Signup;
