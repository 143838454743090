import React, { useState } from "react";
import Checkout from "../../payment/component/checkout";
import LocationDropdown from "../../../common/component/locationDropdown";

const SubscriptionPayment = (props) => {
  const {
    purchasePlans,
    allPlans,
    authData,
    centerId,
    handlecenterId,
    centerName,
  } = props;
  const excludePlan = allPlans.filter(
    ({ id: id1 }) => !purchasePlans.some(({ id: id2 }) => id2 === id1)
  );
  const [couponCode, setCouponCode] = useState("");
  const [subscription, setSubscription] = useState("");

  const product = allPlans.filter((item) => {
    return item.id === subscription;
  });
  const isDisable = !!centerId;

  return (
    <div className="subscribe">
      <h3 className="">Subscription Details</h3>
      <LocationDropdown handlecenterId={handlecenterId} />
      <div className="applyCoupon">
        <label>Apply Coupon</label>
        <div className="applyCouponSubDiv">
          <input
            placeholder="Enter Code Here"
            className="applyCouponTxt"
            type="text"
            name="couponCode"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <button
            className="applyCouponBtn"
            onClick={() => props.applyCouponCode(couponCode)}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="SubscriptionList">
        {allPlans.map((product) => {
          return (
            <div
              key={product.id}
              className={`sub-list-main-div px-20 ${
                isDisable ? "checked" : ""
              }`}
              onClick={() => {
                if (isDisable) {
                  setSubscription(product.id);
                }
              }}
            >
              <label className="plan-content">
                <input
                  type="radio"
                  name="subscription"
                  disabled={!isDisable}
                  defaultChecked={subscription === product.id}
                />
                <div className="sub-list ">
                  <span className="sub-title">{product.title}</span>
                  <p className="sub-details">
                    {centerName &&
                      `Access all routes of ${centerName} on the app and track your official driving test`}
                  </p>
                </div>
              </label>
              <h4 className={"sub-price"}>
                <span className="price-sign">&#163;</span>
                <span className="price">
                  {product.price}/<sub> Per Monthly</sub>
                </span>
              </h4>
            </div>
          );
        })}
      </div>
      {subscription && (
        <Checkout
          centerId={centerId}
          productId={subscription}
          couponCode={couponCode}
          label={`Pay with stripe`}
          email={authData.profile.email}
          name="Test Routes"
          description="Power by Stripe"
          amount={product.price}
          currency={product.currency}
          stripePublishableKey={authData.config.stripeKey}
          onSubmitPayment={props.handlePayment}
        />
      )}
    </div>
  );
};

export default SubscriptionPayment;
