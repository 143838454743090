import React, { useEffect } from "react";
import { connect } from "react-redux";
import Signup from "../component/signup";
import * as LoginActions from "../store/auth.action";

const SignupContainer = (props) => {
  useEffect(() => {
    if (props.auth) {
      props.history.push("/");
    }
  }, []);

  return (
    <>
      <Signup
        loading={props.authLoading}
        signup={props.signup}
        logo={props.logo}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.common,
});

const mapDispatchToProps = (dispatch) => ({
  signup: (data) => LoginActions.signup(data, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
