import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import LogoPro from "../../../assets/images/logoPro.png";
import Logo from "../../../assets/images/logo.png";
import Footer from "../../../common/component/footer";
import InputComponent from "../../../common/form/input";
import { getAuthData } from "../../../common/authService";

const VerifyEmail = (props) => {
  const authData = getAuthData();

  const appMode = localStorage.getItem("app_mode");

  return (
    <section className="auth-section login-section d-flex align-items-center">
      <div className="container">
        <div className="verify-email-container d-flex justify-content-between flex-column">
          <header className="header d-flex align-items-center justify-content-between">
            <div className="logo">
              <Link to="/login">
                {appMode === "standard" ? (
                  <img src={Logo} alt="logo" />
                ) : (
                  <img src={LogoPro} alt="LogoPro" />
                )}
              </Link>
            </div>
          </header>
          <div className="section-heading">
            <h3 className="mb-0 mt-0 text-primary font-bold">Welcome,</h3>
            <p className="mb-0 mt-0">Sign in to continue!</p>
          </div>
          <div className="login-box">
            <Formik
              initialValues={{
                code: "",
                email: authData?.profile?.email || "",
              }}
              onSubmit={props.onSubmit}
              validationSchema={ValidationSchema}
            >
              {({ handleSubmit, values }) => (
                <form
                  onSubmit={handleSubmit}
                  className="change-password-wrapper"
                >
                  <h3 className="text-primary mt-0 mb-4 font-bold">
                    Verify Email
                  </h3>
                  <div className="form-item">
                    <Field name="code" type="password" value={values.code}>
                      {(props) => (
                        <InputComponent
                          {...props}
                          name="code"
                          type="number"
                          label="Verification Code"
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      component="span"
                      className="error"
                      name="code"
                    />
                    {/* <Field name="email" type="text" value={values.email} /> */}
                  </div>
                  <div className="text-center">
                    <button
                      disabled={props.loading}
                      type="submit"
                      className="button button-bordered-primary button-bordered"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="no-account-text text-center">
            <span>
              Don't have a code?{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={props.resendCode}
              >
                Resend code
              </span>
            </span>
          </div>
          <div className="no-account-text text-center mt-2">
            <span>
              Don't have an account?{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={props.signUp}
              >
                Sign Up
              </span>
            </span>
          </div>
          <Footer />
        </div>
      </div>
    </section>
  );
};

const ValidationSchema = Yup.object({
  code: Yup.string().required("Please Enter Verification Code"),
});

export default VerifyEmail;
