import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Home from "../component/home";
import { createAction } from "../../../common/utility";
import { API_CONFIG } from "../../../common/constant";
import {
  getToken,
  getAuthData,
  setAuthData,
} from "../../../common/authService";
import successImage from "../../../assets/images/check.jpeg";
import faildImage from "../../../assets/images/close.jpeg";
// import Checkout from '../../payment/component/checkout';
import Logo from "../../../assets/images/logo.png";
import LogoPro from "../../../assets/images/logoPro.png";
import userIcon from "../../../assets/images/user-icon.png";
import homeIcon from "../../../assets/images/home-icon.png";
import keyIcon from "../../../assets/images/key-icon.png";
import logoutIcon from "../../../assets/images/logout-icon.png";
import ChangePassword from "../component/changePassword";
import Footer from "../../../common/component/footer";
import * as LoginActions from "../../auth/store/auth.action";
import VerifyEmail from "../component/verifyEmail";
import SubscriptionPayment from "../component/subscriptionPayment";
import { Link, useNavigate } from "react-router-dom";

const HomeContainer = (props) => {
  const [tab, setTab] = useState("");
  const [testData, setTestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [history, setHistory] = useState(false);
  const [purchasePlans, setPurchasePlans] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [paymentDoneStatus, setPaymentDoneStatus] = useState(false);

  const navigate = useNavigate();
  const authData = getAuthData();

  const onSignUpClick = (event) => {
    onLogout(event);
    navigate("/signup");
  };

  const verifyEmail = (data) => {
    setLoading(true);
    const headers = { accesstoken: getToken() };
    console.log("verifyEmail headers => ", headers);
    axios
      .post(API_CONFIG.verifyEmail, data, { headers })
      .then((response) => {
        console.log(response);
        const authData = getAuthData();
        authData.profile.isVerified = true;
        setAuthData(authData);
        setLoading(false);

        onComponentMount();
      })
      .catch((error) => {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }
        props.addNotification({ type: "error", message: message });
        setLoading(false);
      });
  };

  const resendCode = () => {
    const headers = { accesstoken: getToken() };
    axios
      .post(`${API_CONFIG.verifyEmail}/resend`, {}, { headers })
      .then((response) => {
        props.addNotification({
          type: "success",
          message: response.data.message,
        });
      })
      .catch((error) => {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }
        props.addNotification({ type: "error", message: message });
      });
  };

  const onComponentMount = () => {
    fetchHistory({ limit, page });
    fetchProfile().then(() => {
      fetchPlans();
    });
    window.addEventListener("scroll", handleScroll());
  };

  const togglePaymentDropdown = () => {
    setSubscription(!subscription);
    setShowChangePassword(false);
    setIsDropdownActive(false);
    setPaymentModalOpen(false);
    setHistory(subscription);
  };

  const toggleLogoutDropDown = (event) => {
    event.stopPropagation();
    setIsDropdownActive(!isDropdownActive);
  };

  const onLogout = (event) => {
    event.stopPropagation();
    LoginActions.logout();
    localStorage.removeItem("authData");
    props.logout();
  };

  const toggleChangePassword = (event) => {
    event.stopPropagation();
    setShowChangePassword(true);
    setIsDropdownActive(false);
    setSubscription(false);
    setPaymentModalOpen(false);
    setHistory(false);
  };
  const toggleHistory = () => {
    setShowChangePassword(false);
    setIsDropdownActive(false);
    setSubscription(false);
    setPaymentModalOpen(false);
    setHistory(true);
  };

  const handleScroll = () => {
    if (!loading && !isScrolling && totalPage !== page && testData.length > 0) {
      let lastLi = document.querySelector(
        ".history-card-wrapper > .history-card:last-child"
      );
      const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
      const pageOffset = window.pageYOffset + window.innerHeight;

      if (testData.length >= 10 && pageOffset > lastLiOffset) {
        const params = {
          limit: limit,
          page: page + 1,
        };
        fetchHistory(params, true);
        setPage(page + 1);
      }
    }
  };

  const handleTabChange = (tab) => {
    const params = {
      limit: limit,
      page: 1,
    };
    setTab(tab);
    setTestData([]);
    fetchHistory(params);
  };

  const fetchHistory = (data, isScrolling = false) => {
    const headers = {
      accesstoken: getToken(),
    };
    setLoading(!isScrolling);
    setIsScrolling(isScrolling);
    const url = tab ? `${API_CONFIG.traversal}/${tab}` : API_CONFIG.traversal;
    axios
      .get(url, { params: data, headers })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setTestData([...testData, ...response.data.traversals]);
          setIsScrolling(false);
          setTotalPage(
            Math.ceil((response.data.total + limit - 1) / limit - 1)
          );
          setTotal(response.data.total);
        }
      })
      .catch((error) => {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }
        props.addNotification({ type: "error", message: message });
        setLoading(false);
        setIsScrolling(false);
      });
  };

  const handleChangePassword = (data) => {
    const headers = {
      accesstoken: getToken(),
    };
    const params = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    setActionLoading(true);
    return axios
      .post(API_CONFIG.changePassword, params, { headers })
      .then((response) => {
        props.addNotification({
          type: "success",
          message: response.data.message,
        });
        setActionLoading(false);
        setShowChangePassword(false);
      })
      .catch((error) => {
        let message = error.message;
        console.log(error.response);

        if (error.response) {
          message = error.response.data.message;
        }
        props.addNotification({ type: "error", message: message });
        setActionLoading(false);
      });
  };

  const handlePayment = (productId, centerId) => (stripeToken) => {
    const headers = {
      accesstoken: getToken(),
    };
    setIsPaymentLoading(true);
    return axios
      .post(
        API_CONFIG.payment,
        { stripeToken: stripeToken.id, productId, centerId },
        { headers }
      )
      .then(() => {
        const authData = getAuthData();
        props.addNotification({
          type: "success",
          message: "Subscription payment completed successfully.",
        });

        setAuthData({
          ...authData,
          profile: { ...authData.profile, userType: "paid" },
          config: { ...authData.config, subscription_status: "succeeded" },
        });
        setAuthData({
          ...authData,
          profile: { ...authData.profile, userType: "paid" },
          config: { ...authData.config, subscription_status: "succeeded" },
        });

        setSubscription(true);
        setIsPaymentLoading(false);
        setPaymentSuccess(true);
        setCurrentPlan(productId);
        filterPlans(allPlans);
        hidePaymentModal();
        setTimeout(() => {
          toggleHistory();
        }, 2500);
      })
      .catch((error) => {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }
        setIsPaymentLoading(false);
        setPaymentError(true);
        setPaymentErrorMessage(message);
        setPaymentDoneStatus(false);
        hidePaymentModal();
      });
  };

  const fetchProfile = () => {
    return new Promise((resolve) => {
      axios
        .get(API_CONFIG.profile, { headers: { accesstoken: getToken() } })
        .then((response) => {
          setAuthData({ ...getAuthData(), ...response.data });
          setCurrentPlan(response.data.profile.currentPlan);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          resolve();
        });
    });
  };

  const filterPlans = (allPlans) => {
    const purchasePlans = allPlans
      .map((product) => {
        if (currentPlan === "") {
          if (product.id.includes("upgrade")) {
            return null;
          }
        } else if (currentPlan.includes("basic")) {
          if (!product.id.includes("upgrade")) {
            return null;
          }
        } else if (
          currentPlan.includes("upgrade") ||
          currentPlan.includes("advanced")
        ) {
          return null;
        }
        return product;
      })
      .filter(Boolean);
    setPurchasePlans(purchasePlans);
    setAllPlans(allPlans);
  };

  const fetchPlans = () => {
    if (!currentPlan.includes("advanced")) {
      axios
        .get(API_CONFIG.purchasePlans, { headers: { accesstoken: getToken() } })
        .then((response) => {
          filterPlans(response.data.products);
        })
        .catch((error) => console.log(error));
    }
  };

  const applyCouponCode = (couponCode) => {
    axios
      .post(
        API_CONFIG.couponCodeApply,
        {
          couponCode: couponCode,
        },
        { headers: { accesstoken: getToken() } }
      )
      .then((response) => {
        filterPlans(response.data.products);
      })
      .catch((error) => {
        props.addNotification({
          type: "error",
          message: "Coupon code invalid or expired",
        });
        console.log(error);
      });
  };

  const hidePaymentModal = () => {
    setTimeout(() => {
      setPaymentSuccess(false);
      setPaymentErrorMessage("");
      setPaymentError(false);
    }, 2000);
  };

  useEffect(() => {
    let previousURL = document.referrer;
    previousURL = previousURL.split("?")[0];

    const origin = window.location.origin;

    const paymentUrl = origin + "/subscription";
    if (previousURL === paymentUrl) {
      setSubscription(true);
    } else {
      setHistory(true);
    }
    const authData = getAuthData();
    if (authData?.profile?.isVerified) {
      onComponentMount();
    }

    return () => {
      window.removeEventListener("scroll", handleScroll());
    };
  }, []);

  console.log(`authData`, authData);

  return (
    <>
      {!authData?.profile?.isVerified ? (
        <VerifyEmail
          loading={loading}
          onSubmit={verifyEmail}
          resendCode={resendCode}
          signUp={onSignUpClick}
        />
      ) : (
        <section
          className="home-section"
          onClick={() => setIsDropdownActive(false)}
        >
          <div className="container">
            <div className="gray-wrapper">
              <header className="header  d-flex align-items-center justify-content-between">
                <Link to="/">
                  <div
                    className="logo"
                    onClick={() => setShowChangePassword(false)}
                  >
                    {props.logo.logo === "testRoutePro" ? (
                      <img src={LogoPro} alt="logoPro" />
                    ) : (
                      <img src={Logo} alt="logo" />
                    )}
                  </div>
                </Link>
                <div className="link-wrapper d-flex align-items-center">
                  {/* {!!this.state.purchasePlans.length &&
                        <div className='mr-2'>
                            <div className='profile-dropdown' onClick={this.togglePaymentDropdown}>
                                <button className="payment-btn">
                                    <span>Subscribe</span>
                                </button>
                            </div>
                        </div>
                        } */}
                  <Link to="subscription/plan">
                    <div className="subcription-details">
                      <span>
                        <img src={homeIcon} alt="Home Icon" />
                        Subscription Details:
                        <b>{authData.profile.subscriptionType}</b>
                      </span>
                    </div>
                  </Link>
                  <div
                    className="profile-dropdown"
                    onClick={(event) => toggleLogoutDropDown(event)}
                  >
                    <span className="user-name">
                      <img src={userIcon} alt="User Icon" />
                      {authData.profile.fullName}
                    </span>
                    {isDropdownActive && (
                      <ul>
                        <li onClick={(event) => toggleChangePassword(event)}>
                          <img src={keyIcon} alt="Key Icon" />
                          Change Password
                        </li>
                        <li onClick={(event) => onLogout(event)}>
                          <img src={logoutIcon} alt="Logout Icon" />
                          Log out
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </header>
              {showChangePassword && (
                <ChangePassword
                  loading={actionLoading}
                  onSubmit={handleChangePassword}
                />
              )}
              {history && (
                <Home
                  {...{ testData, loading, tab, isScrolling }}
                  onTabChange={handleTabChange}
                  toggleDropDown={toggleLogoutDropDown}
                  onLogout={onLogout}
                />
              )}
              {subscription && (
                <SubscriptionPayment
                  purchasePlans={purchasePlans}
                  allPlans={allPlans}
                  authData={authData}
                  applyCouponCode={applyCouponCode}
                  handlePayment={handlePayment}
                />
              )}
              <Footer />
            </div>
          </div>
          {(paymentSuccess || paymentError || isPaymentLoading) && (
            <div className="payment-status-modal d-flex align-items-center justify-content-center">
              <div className="status-modal-content d-flex flex-column align-items-center">
                {!paymentError && (
                  <h6 className="mt-0 mb-3 font-bold">
                    {paymentSuccess
                      ? "Your Payment is recived"
                      : "Please wait payment is under process"}
                  </h6>
                )}
                {paymentError && (
                  <h6 className="mt-0 mb-3 font-bold text-primary">
                    {paymentErrorMessage}
                  </h6>
                )}
                {isPaymentLoading && (
                  <svg className="spinner" viewBox="0 0 50 50">
                    <circle
                      className="path"
                      cx="25"
                      cy="25"
                      r="20"
                      fill="none"
                      strokeWidth="5"
                    ></circle>
                  </svg>
                )}
                {paymentSuccess && <img alt="success" src={successImage} />}
                {paymentError && <img alt="faild" src={faildImage} />}
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addNotification: (data) => dispatch(createAction("ADD_NOTIFICATION", data)),
  logout: () => dispatch(createAction("LOGOUT")),
});

export default connect(null, mapDispatchToProps)(HomeContainer);
