import React from "react";
import { GoLocation } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { API_CONFIG, appModes, appMode } from "../constant";
import axios from "axios";
import { getToken } from "../authService";

const LocationDropdown = ({ handlecenterId }) => {
  const [locationList, setLocationList] = React.useState([]);
  const [dropdownPopupState, setDropdownPopupState] = React.useState(false);
  const [sortedlocationList, setSortedLocationList] = React.useState([]);
  const [selectState, setSelectState] = React.useState(false);
  const [selectedlocation, setSelectedLocation] = React.useState({});

  React.useEffect(() => {
    axios
      .get(`${API_CONFIG.locationList}`, {
        headers: { accesstoken: getToken() },
      })
      .then((response) => {
        if (appMode === appModes.pro) {
          setLocationList(response.data.centers);
          setSortedLocationList(response.data.centers);
        }
        if (appMode === appModes.standard) {
          setLocationList(response.data);
          setSortedLocationList(response.data);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (e) => {
    setSortedLocationList(
      locationList.filter((location) =>
        location.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setSelectState(false);
    setDropdownPopupState(false);
    handlecenterId(location.id, location.name);
  };
  return (
    <div className="locationDropdown">
      <div
        className={selectState ? "blackBox disabledSelectMain" : "blackBox"}
        onClick={() => {
          setDropdownPopupState(!dropdownPopupState);
          setSelectState(!selectState);
        }}
      >
        <span>{selectedlocation.name || "Select Centre"}</span>
        <i>
          <GoLocation />
        </i>
      </div>
      <div
        className={`${
          dropdownPopupState
            ? "dropdownPopup dropdownPopup__show"
            : "dropdownPopup"
        }`}
      >
        <div className="dropdownPopup__closeIcon">
          <i
            onClick={() => {
              setDropdownPopupState(false);
              setSelectState(false);
            }}
          >
            <IoClose />
          </i>
        </div>
        <div className="blackBox dropdownPopup__search">
          <input
            type="text"
            placeholder="Search here..."
            onChange={handleChange}
          />
          <i>
            <IoMdSearch />
          </i>
        </div>

        <div className="dropdownPopup__locationList">
          {sortedlocationList.map((location) => (
            <div
              className="blackBox dropdownPopup__location"
              key={location.id}
              onClick={() => handleLocationSelect(location)}
            >
              <span>{location.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationDropdown;
