export const getFullDate = (date) => {
    const startedDate = new Date(date);
    const dd = String(startedDate.getDate()).padStart(2, '0');
    const mm = String(startedDate.getMonth() + 1).padStart(2, '0');
    const yyyy = startedDate.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
}

export const getTime = (date) => {
    const d = new Date(date);
    const n = d.toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit'
    });
    return n;
}

export const fromDollarToCent = (amount) => Math.round(amount * 100);
