export const appModes = {
  pro: "pro",
  standard: "standard",
};
export const appMode = localStorage.getItem("app_mode");
const proEndpoint = process.env.REACT_APP_API_PRO_ENDPOINT;
const standardEndpoint = process.env.REACT_APP_API_STANDARD_ENDPOINT;
const apiEndPoint = appMode === appModes.pro ? proEndpoint : standardEndpoint;

export const API_CONFIG = {
  login: `${apiEndPoint}/user/login`,
  logout: `${apiEndPoint}/user/logout`,
  profile: `${apiEndPoint}/user/profile`,
  subscriptionProfile: `${apiEndPoint}/user/subscription/profile`,
  purchasePlans: `${apiEndPoint}/payment/products`,
  signup: `${apiEndPoint}/user/signup`,
  forgetPassword: `${apiEndPoint}/user/forget-password`,
  resetPassword: `${apiEndPoint}/user/reset-password`,
  traversal: `${apiEndPoint}/traversal`,
  payment: `${apiEndPoint}/payment/transact`,
  changePassword: `${apiEndPoint}/user/change-password`,
  verifyEmail: `${apiEndPoint}/user/email/verify`,
  couponCodeApply: `${apiEndPoint}/coupon/validate`,
  subscribedPlans: `${apiEndPoint}/user/profile`,
  locationList: `${apiEndPoint}/center`,
};
