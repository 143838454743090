import React from "react";
import { appModes } from "../constant";

const ToggleButton = (props) => {
  const handleAppTypeChange = (userType) => {
    props.form.setFieldValue("appType", userType);
    props.logo.setLogo(userType);
    localStorage.setItem(
      "app_mode",
      userType === "testRoutePro" ? appModes.pro : appModes.standard
    );

    window.location.reload();
  };
  // const handleChange = () => {

  // };

  return (
    <div className="form-item">
      <div className="login-type">
        <span>{props.title}</span>
        <div className="login-type-tgl-btn">
          <button
            type="button"
            className={`${props.logo.logo === "testRoutePro" ? "active" : ""}`}
            onClick={() => {
              handleAppTypeChange("testRoutePro");
            }}
            disabled={props.logo.logo === "testRoutePro"}
            // onClick={handleChange}
          >
            Pro
          </button>
          <button
            type="button"
            className={`${props.logo.logo ? "" : "active"}`}
            onClick={() => handleAppTypeChange("")}
            disabled={props.logo.logo === ""}
          >
            Standard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
