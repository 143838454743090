import React, { useEffect } from "react";
import { connect } from "react-redux";
import Login from "../component/login";
import * as LoginActions from "../store/auth.action";

const LoginContainer = (props) => {
  useEffect(() => {
    if (props.auth) {
      props.history.push("/");
    }
  }, [props]);

  return (
    <>
      <Login
        loading={props.authLoading}
        login={props.login}
        logo={props.logo}
      />
    </>
  );
};

const handleData = (data) => {
  if (!data.appType) {
    delete data.appType;
  }
  return data;
};

const mapStateToProps = (state) => ({
  ...state.common,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => {
    LoginActions.login(handleData(data), dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
