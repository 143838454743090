import React from "react";
import { Link } from "react-router-dom";

const SubscribedPlansCard = ({ plan, homeHandler }) => {
  const getFullDate = (date) => {
    const startedDate = new Date(date);
    const dd = String(startedDate.getDate()).padStart(2, "0");
    const mm = String(startedDate.getMonth() + 1).padStart(2, "0");
    const yyyy = startedDate.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };

  // function to check a date is before a date
  const isBefore = (date1) => {
    return date1 < new Date() ? true : false;
  };

  return (
    <div
      className={`subscribedPlansCard ${
        isBefore(plan.paymentExpiryDate) ? "subscribedPlansCard__expired" : ""
      }`}
    >
      <h5>{plan.centerId.name}</h5>
      <p>Date : {getFullDate(plan.paymentDate)}</p>
      <p>Expiry date : {getFullDate(plan.paymentExpiryDate)}</p>
      <div>
        <p>Amount : &#163;{plan.paymentData.amount}</p>
        {isBefore(plan.paymentExpiryDate) && (
          <button onClick={homeHandler}>Renew</button>
        )}
      </div>
    </div>
  );
};

export default SubscribedPlansCard;
