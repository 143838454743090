import React, { useState, useEffect } from "react";
import { API_CONFIG } from "../../../common/constant";
import axios from "axios";
import { getToken } from "../../../common/authService";
import SubscribedPlansCard from "../../../common/component/subscribedPlansCard";

const SubscribedPlans = ({ homeHandler }) => {
  const [subscribedPlans, setSubscribedPlans] = useState([]);
  useEffect(() => {
    axios
      .get(`${API_CONFIG.subscribedPlans}`, {
        headers: { accesstoken: getToken() },
      })
      .then((response) => {
        setSubscribedPlans(response.data.profile.subscription);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="subscribedPlans">
      <h3>My Subcription</h3>
      {subscribedPlans?.length ? (
        <>
          <div>
            {subscribedPlans?.map((plan) => (
              <SubscribedPlansCard
                key={plan._id}
                plan={plan}
                homeHandler={homeHandler}
              />
            ))}
          </div>

          <button onClick={homeHandler}>Add new subcription</button>
        </>
      ) : (
        <div className="loader text-center">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
        </div>
      )}
    </div>
  );
};

export default SubscribedPlans;
