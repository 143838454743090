import axios from "axios";
import { API_CONFIG } from "../../../common/constant";
import * as types from "../../../common/action-types";
import { setAuthData } from "../../../common/authService";
import * as Utility from "../../../common/utility";

const login = (data, dispatch) => {
  dispatch(Utility.createAction(types.AUTH_INIT));
  axios
    .post(API_CONFIG.login, data, {
      headers: {
        platform: "web",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        setAuthData(response.data);
        dispatch(Utility.createAction(types.AUTH_SUCCESS));
        localStorage.removeItem("fullName");
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
    })
    .catch((error) => {
      let message = error.message;
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(Utility.createAction(types.AUTH_ERROR, { message: message }));
    });
};

const logout = () => {
  axios
    .post(API_CONFIG.logout)
    .then(() => {})
    .catch(() => {});
};

const signup = (data, dispatch) => {
  dispatch(Utility.createAction(types.SIGNUP_INIT));
  axios
    .post(API_CONFIG.signup, data, {
      headers: {
        platform: "web",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        setAuthData(response.data);
        dispatch(Utility.createAction(types.SIGNUP_SUCCESS));
        localStorage.removeItem("fullName");
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
    })
    .catch((error) => {
      let message = error.message;
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(Utility.createAction(types.SIGNUP_ERROR, { message }));
    });
};

const forgotPassword = (data, dispatch) => {
  dispatch(Utility.createAction(types.FORGOT_PASSWORD_INIT));
  axios
    .post(API_CONFIG.forgetPassword, data)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          Utility.createAction(types.FORGOT_PASSWORD_SUCCESS, {
            ...data,
            message: response.data.message,
          })
        );
      }
    })
    .catch((error) => {
      let message = error.message;
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(Utility.createAction(types.FORGOT_PASSWORD_ERROR, { message }));
    });
};

const resetPassword = (data, dispatch) => {
  dispatch(Utility.createAction(types.RESET_PASSWORD_INIT));
  axios
    .post(API_CONFIG.resetPassword, data)
    .then((response) => {
      if (response.status === 200) {
        dispatch(
          Utility.createAction(types.RESET_PASSWORD_SUCCESS, {
            ...response.data,
          })
        );
      }
    })
    .catch((error) => {
      let message = error.message;
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(Utility.createAction(types.RESET_PASSWORD_ERROR, { message }));
    });
};

export { login, logout, signup, forgotPassword, resetPassword };
